<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">盘点单</span>
    </div>

    <div class="title">盘点单</div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item label="盘点单号" :span="2">
        {{ detail.inventoryOrder }}
      </a-descriptions-item>
      <a-descriptions-item label="盘点负责人">
        {{ detail.applicant }}
      </a-descriptions-item>
      <a-descriptions-item label="盘点开始时间">
        {{ detail.inventoryTime }}
      </a-descriptions-item>

      <a-descriptions-item label="申请原因" :span="2">
        {{ detail.reason }}
      </a-descriptions-item>
      <a-descriptions-item label="备注" :span="2">
        {{ detail.remark }}
      </a-descriptions-item>
      <a-descriptions-item label="附件" :span="2">
        <a-space v-if="detail.attachmentList">
          <a
            v-for="item in detail.attachmentList"
            :key="item"
            :href="item.completePath"
            >{{ item.name }}</a
          >
        </a-space>
      </a-descriptions-item>
      <a-descriptions-item label="盘点状态" :span="2">
        <DataDictFinder
          dictType="AssetInventoryStatus"
          :dictValue="detail.status"
          iconType="text"
        />
      </a-descriptions-item>
    </a-descriptions>

    <div class="title">物料清单</div>
    <Goods
      type="check"
      :selected="Array.isArray(detail.materialList) ? detail.materialList : []"
    />

    <div class="center">
      <a-space>
        <a-button @click="cancel">关闭</a-button>
        <a-button type="primary" :loading="loading" @click="pass">
          保存
        </a-button>
        <!-- <template v-if="detail.status === 'REVIEWING'">
   
        </template> -->
      </a-space>
    </div>
  </div>
</template>

<script>
import { check, fetchDetail } from "../api/check";
import Goods from "./components/goods.vue";

export default {
  components: {
    Goods,
  },

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      loading: false,
    };
  },

  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const { id } = this.$route.query;
      fetchDetail({
        id,
      }).then((res) => {
        if (res) {
          this.detail = res;
        }
      });
    },
    pass() {
      this.loading = true;
      check({
        ...this.detail,
      })
        .then(() => {
          this.getDetail();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  margin: 8px 0;
}
.center {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>

<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;
  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>